import Service, { inject as service } from '@ember/service';
import config from '../config/environment';
import { v4 as uuidv4 } from 'uuid';
import {
  AnalyticsBaseClientDelegate,
  AnalyticsClient,
  AnalyticsProperty
} from 'ti-es-analytics-client'

const LOCAL_STORAGE__UNIQUE_DEVICE_ID = "analytics.uniqueDeviceId";

const LIBRARY_DIMENSION_INDEX = 1;

const categoriesForWhichToUseValueForJournal = [
  'ArticleDownloadSuccess',
  'problematicJournalNoticeViewed',
  'DocumentDeliveryFulfillment',
];

const categoriesForWhichToUseValueForProblematicDomain = [
  'problematicDomainNoticeViewed',
];

const categoriesForWhichToUseActionForArticleId = [
  'ArticleDownloadSuccess',
  'PublicAPIArticleInContext',
  'PublicAPIContentLocation',
  'PublicAPIFullTextFile',
  'PublicAPIViewJournalContentsClick',
  'DocumentDeliveryFulfillment',
];

const categoriesForWhichToUseActionForDoi = [
  'CrossrefTimeout',
  'UnpaywallRetrievedUrls',
  'UnpaywallRetrievedNoUrls',
];

const categoriesForWhichToUseActionForDoiOrPmid = [
  'ForwardingLinkSuccess',
  'ForwardingLinkToIdentifier',
];

const tiAnalyticsEventCategoryRenames = {
  'ArticleDownloadSuccess' : 'ArticleDownload',
  'ArticleTryAgainTapped' : 'ArticleTryAgain',
  'CrossrefTimeout' : 'Crossref',
  'ForwardingLinkSuccess' : 'ForwardingLink',
  'ForwardingLinkToIdentifier' : 'ForwardingLink',
  'PublicAPIArticleInContext' : 'PublicAPI',
  'PublicAPIContentLocation' : 'PublicAPI',
  'PublicAPIFullTextFile' : 'PublicAPI',
  'PublicAPIViewJournalContentsClick' : 'PublicAPI',
  'UnpaywallRetrievedNoUrls' : 'Unpaywall',
  'UnpaywallRetrievedUrls' : 'Unpaywall',
};

const tiAnalyticsEventTypesForEventCategories = {
  'ArticleDownloadSuccess' : 'Success',
  'ArticleTryAgainTapped' : 'Tapped',
  'CrossrefTimeout' : 'Timeout',
  'ForwardingLinkSuccess' : 'Success',
  'ForwardingLinkToIdentifier' : 'ToIdentifier',
  'PublicAPIArticleInContext' : 'ArticleInContext',
  'PublicAPIContentLocation' : 'ContentLocation',
  'PublicAPIFullTextFile' : 'FullTextFile',
  'PublicAPIViewJournalContentsClick' : 'ViewJournalContentsClick',
  'UnpaywallRetrievedNoUrls' : 'RetrievedNoUrls',
  'UnpaywallRetrievedUrls' : 'RetrievedUrls',
};

const tiAnalyticsEventCategoryFor = function(properties) {
  return tiAnalyticsEventCategoryRenames[properties.category] || properties.category;
}

const tiAnalyticsEventTypeFor = function(properties) {
  return tiAnalyticsEventTypesForEventCategories[properties.category] || properties.action;
}

const tiAnalyticsJournalIdFor = function(properties) {
  return categoriesForWhichToUseValueForJournal.includes(properties.category) ? properties.value : undefined;
}

const tiAnalyticsProblematicDomainIdFor = function(properties) {
  return categoriesForWhichToUseValueForProblematicDomain.includes(properties.category) ? properties.value : undefined;
}

const tiAnalyticsArticleIdFor = function(properties) {
  return categoriesForWhichToUseActionForArticleId.includes(properties.category) ? properties.action : properties.articleId;
}

const tiAnalyticsDoiFor = function(properties) {
  // For certain categories action is either a PMID or a DOI.
  if (categoriesForWhichToUseActionForDoiOrPmid.includes(properties.category) && properties.action) {
    // if action is non-numeric it's a DOI, use it
    return properties.action.match(/^\d+$/) ? undefined : properties.action;
  }
  // For certain other categories action is always a DOI
  return categoriesForWhichToUseActionForDoi.includes(properties.category) ? properties.action : properties.doi;
}

const tiAnalyticsPmidFor = function(properties) {
  // For certain categories action is either a PMID or a DOI.
  if (categoriesForWhichToUseActionForDoiOrPmid.includes(properties.category) && properties.action) {
    // if action is numeric it's a PMID, use it
    return properties.action.match(/^\d+$/) ? properties.action : undefined;
  }
  return properties.pmid;
}

/* TODO: ^ The event creation code that relies on all these functions above should be extracted into a utility that can be tested alone. ^ */
export default class AnalyticsService extends Service {
  @service applicationSession
  tiAnalyticsClient = null
  staticBackgroundDimensions = {
    os: window.navigator.platform,
    appName: "LKWeb",
    property: config.environment === 'production' ? AnalyticsProperty.PRODUCTION_FOREGROUND : AnalyticsProperty.NON_PRODUCTION_FOREGROUND,
    uniqueDeviceId: undefined,
    browser: window.navigator.appCodeName,
    browserVersion: window.navigator.appVersion,
    userAgent: window.navigator.userAgent,
  }

  dynamicBackgroundDimensions = {
    libraryId: undefined,
    userId: undefined,
    url: undefined,
    utmSource: undefined,
    utmMedium: undefined,
    utmCampaign: undefined,
    utmTerm: undefined,
    utmContent: undefined,
    product: undefined,
  }

  eventDimensions = {
    journalId: undefined,
    issueId: undefined,
    articleId: undefined,
    doi: undefined,
    pmid: undefined,
    openAccess: undefined,
    lendingLibraryId: undefined,
    requestingLibraryId: undefined,
    libraryGroupId: undefined,
  }

  constructor() {
    super(...arguments);

    const uniqueDeviceIdFromLocalStorage = localStorage.getItem(LOCAL_STORAGE__UNIQUE_DEVICE_ID);
    if ( uniqueDeviceIdFromLocalStorage ) {
      this.staticBackgroundDimensions.uniqueDeviceId = uniqueDeviceIdFromLocalStorage;
    } else {
      const newUniqueDeviceId = uuidv4();
      localStorage.setItem(LOCAL_STORAGE__UNIQUE_DEVICE_ID, newUniqueDeviceId);
      this.staticBackgroundDimensions.uniqueDeviceId = newUniqueDeviceId;
    }

    const outerThis = this;

    const tiAnalyticsClientDelegate = new (class extends AnalyticsBaseClientDelegate {
      getBackgroundDimensions() {
        return {
          ...outerThis.staticBackgroundDimensions,
          ...outerThis.dynamicBackgroundDimensions,
          ...outerThis.eventDimensions,
        };
      }

      logError(error) {
        console.error(error);
      }
    })(config.analyticsServiceAddress);

    this.tiAnalyticsClient = new AnalyticsClient(tiAnalyticsClientDelegate);
    this.defineTrackedPatterns();

  }

  /**
   * Defines all the patterns tracked in the app
   *
   */
  defineTrackedPatterns() {
    //leaving behind framework for tracked patterns for use in the future.
  }

  getUserId() {
    if(config.recordAnalyticsMetrics) {
      const loggedInUser = this.applicationSession.loggedInUser;

      if(loggedInUser && loggedInUser.userId) {
        return loggedInUser.userId
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  async recordEvent(properties) {
    if(config.recordAnalyticsMetrics) {
      const tiEvent = {
        articleId: tiAnalyticsArticleIdFor(properties),
        doi: tiAnalyticsDoiFor(properties),
        pmid: tiAnalyticsPmidFor(properties),
        openAccess: properties.openAccess,
        withinLibraryHoldings: properties.withinLibraryHoldings,
        url: window.location.href,
        eventCategory: tiAnalyticsEventCategoryFor(properties),
        eventType: tiAnalyticsEventTypeFor(properties),
        gaEventLabel: properties.label,
        gaEventValue: properties.value,
        journalId: tiAnalyticsJournalIdFor(properties),
        libraryGroupId: properties.libraryGroupId,
        lendingLibraryId: properties.lendingLibraryId,
        requestingLibraryId: properties.requestingLibraryId,
        extras: {
          lendingLibraryName: properties.lendingLibraryName,
          requestingLibraryName: properties.requestingLibraryName,
          requesterEmail: properties.requesterEmail,
          journalName: properties.journalName,
          issn: properties.issn,
          eissn: properties.eissn,
          articleYear: properties.articleYear,
          fulfillmentRequestId: properties.fulfillmentRequestId,
          referenceNumber: properties.referenceNumber,
          hoursSinceCreated: properties.hoursSinceCreated,
          declinedReason: properties.declinedReason,
          issueYear: properties.issueYear,
          authors: properties.authors,
          problematicDomainId: tiAnalyticsProblematicDomainIdFor(properties),
          requestedId: properties.requestedId,
          documentDeliveryProvider: properties.documentDeliveryProvider,
        }
      };
      this.eventDimensions = { ...tiEvent };
      const conversions = this.checkForConversions(tiEvent);
      if (conversions && conversions.length) {
        tiEvent.conversions = conversions;
      }
      try {
        await this.tiAnalyticsClient.sendEvent(tiEvent);
      } catch (err) {
        console.log(`Error recording analytics event: ${err.stack}`);
      }
    }
  }

  setCustomDimension(dimensionIndex, value) {
    if ( dimensionIndex == LIBRARY_DIMENSION_INDEX ) {
      this.dynamicBackgroundDimensions.libraryId = value;
    }
  }

  setProductDimension(value) {
    this.dynamicBackgroundDimensions.product = value;
  }

  getProductDimension() {
    return this.dynamicBackgroundDimensions.product;
  }

  // When the app is opened, utm values on the query string get
  // sent to the analytics service to be saved as dimensions on
  // any event that's recorded in the TI analytics system.
  // Different from a "custom" dimension
  // because these values ga is already recording as dimensions
  // We can't call setCustomDimension as that would double
  // record them in GA
  setUTMDimension(utmKey, value) {
    if (utmKey === 'utm_source') {
      this.dynamicBackgroundDimensions.utmSource = value;
    } else if (utmKey === 'utm_medium') {
      this.dynamicBackgroundDimensions.utmMedium = value;
    } else if (utmKey === 'utm_campaign') {
      this.dynamicBackgroundDimensions.utmCampaign = value;
    } else if (utmKey === 'utm_term') {
      this.dynamicBackgroundDimensions.utmTerm = value;
    } else if (utmKey === 'utm_content') {
      this.dynamicBackgroundDimensions.utmContent = value;
    } else {
      console.log(`Tried to set unrecognized UTM dimension: ${utmKey}`);
    }
  }

  getUTMSource() {
    return this.dynamicBackgroundDimensions.utmSource;
  }

  getUTMMedium() {
    return this.dynamicBackgroundDimensions.utmMedium;
  }

  getUTMCampaign() {
    return this.dynamicBackgroundDimensions.utmCampaign;
  }

  getUTMTerm() {
    return this.dynamicBackgroundDimensions.utmTerm;
  }

  getUTMContent() {
    return this.dynamicBackgroundDimensions.utmContent;
  }

  /*
   *  Initiates a tracked pattern
   *
   *  @param {string} patternName - the key name uniquely identifying the tracked pattern
   *  @param {Object} initialState - each tracked pattern has an object for holding state
   *                                 associated with it.  That object is initialized
   *                                 with this parameters values
   */
  startTrackedPattern(patternName, initialState = {}) {
    const patternDefinition = this.trackedPatternDefinitions[patternName];

    if (!patternDefinition) {
      throw new Error(`startTrackedPattern called for undefined pattern ${patternName}`);
    }

    let activePatterns = this.activePatterns;
    if (!activePatterns) {
      activePatterns = {};
      this.activePatterns = activePatterns;
    }

    activePatterns[patternName] = { ...patternDefinition };

    this.trackedPatternState[patternName] = initialState;
  }

  /**
   * Removes a pattern from the in progress patterns and clears its state
   */
  resetTrackedPattern(patternName) {
    delete this.activePatterns[patternName];
    delete this.trackedPatternState[patternName];
  }

  /**
   * Calls the update state function for the specified pattern with the provided object
   *
   * @param {string} patternName - key name of the pattern we're updating state for
   * @param {Object} updateState - Object of values to pass to the pattern's updateState function
   */
  updateTrackedPattern(patternName, updateState) {
    const pattern = this.activePatterns[patternName];

    if (!pattern) {
      // no pattern started with this name, do nothing
      return;
    }

    if (!this.trackedPatternState[patternName]) {
      // no state stored for a pattern with this name, do nothing
      // this shouldn't really happen, the first check should have returned
      return;
    }

    pattern.updatePatternStateFunc.bind(this.trackedPatternState[patternName])(updateState);
  }

  // Holds the definitions of the patterns that may be tracked
  trackedPatternDefinitions = {}

  // Holds the patterns that are currently in-progress (startPattern has been called, but the goal has not been reached)
  activePatterns = {}

  // The analytics service holds the state objects for in-progress patterns in this property
  trackedPatternState = {}

  /**
   * Define a pattern that may be tracked in the app.  Provide a key name with which to identify
   * the pattern, a function that may be called by application code to update state on the pattern,
   * and a predicate that's called when events are recorded to determine whether the user has reached
   * the goal of the tracked pattern.
   *
   * @param {string} patternName - the keyname for the pattern
   * @param {function} updatePatternStateFunc - optional function that may be called to keep track of state related
   *                                            to the pattern as the user progresses to the goal.  Calls of
   *                                            application code to updatePatternState call this function, passing along the
   *                                            values provided to it, and binding this to the pattern's state object
   * @param {function} goalReachedPredicate - function that is called with event data when recordEvent is called. If it
   *                                         returns true, the user is considered to have reached the goal for the tracked
   *                                         pattern, and the conversions property of the event gets the name of the
   *                                         tracked pattern. "this" is bound to the pattern's state object
   */
  defineTrackedPattern(patternName, updatePatternStateFunc = () => {}, goalReachedPredicate) {
    if (!goalReachedPredicate) {
      throw new Error(`defineTrackedPattern called for pattern "${patternName}" without a goalReachedPredicate.`);
    }

    this.trackedPatternDefinitions[patternName] = {
      updatePatternStateFunc,
      goalReachedPredicate
    };
  }

  /***
   * Determines whether the event being recorded represents the goal of any actively tracked patterns.
   * returns an array of the keynames of the patterns for which the goal is reached
   */
  checkForConversions(event) {
    const completedConversions = [];

    for (let patternName of Object.keys(this.activePatterns)) {
      let pattern = this.activePatterns[patternName];
      if (pattern.goalReachedPredicate.bind(this.trackedPatternState[patternName])(event)) {
        completedConversions.push(patternName);
        this.resetTrackedPattern(patternName);
      }
    }

    return completedConversions;
  }
}


